.skylar-chat-modal {
    border-radius: 10px;
    height: 100%;
    padding: 0 10px;
    overflow: auto;
    box-shadow: 0 7px 29px 0 #64646f33;

    .skyler-head {
        display: flex;
        justify-content: space-between;
        padding: 10px 14px 7px 0;
        border-bottom: 1px solid #088183;

        img {
            width: 100px;
        }
    }

    .skylar-chatbox-body {
        overflow-y: scroll;
        padding: 0;
        display: flex;
        flex-direction: column;

        .left-chat-box {
            white-space: normal;
            min-width: 70px;
            p {
                color: #fff;
                background: #088183;
                border-radius: 15px 15px 15px 15px;
                box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
                display: flex;
                align-items: self-start;
                line-height: 19px;
                padding: 8px;
                margin: 0 8px 0  0;
            }
        }

        .ai-chat-box {
            font-size: 15px;
            line-height: 19px;
            position: relative;
             img {
                position: absolute;
                width: 45px;
                left: -7px;
                top: -14px;
            }
        }

        .right-chat-box {
            float: right;
            display: block;
            width: 100%;
        }

    }

    .skylar-chat-footer {
        display: flex;
        align-items: center;

        textarea {
            resize: none;
        }

        .send-message {
            margin-left: 5px;
        }
    }

}

.skylar-chatbody-outer {
    display: flex;
    align-content: end;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100vh - 142px);
    flex-direction: column;
}

.left-chat-box p img {
    width: 24px;
    margin: 0 4px 0 0;
}
.under-box {
    display: flex;
    flex-wrap: wrap;
}
.under-box .left-chat-box {
    margin-top: 5px;
}
.under-box-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}